import React from 'react'
import 'index.scss'
import reportWebVitals from 'reportWebVitals'
import axios from 'axios'
import {
  applyPolyfills as applyPolyfillsHeader,
  defineCustomElements as definePorscheNavigation,
} from '@porschehn/navigation/loader'
import { IntlApp } from 'components/app/IntlApp'
import { PorscheDesignSystemProvider } from '@porsche-design-system/components-react'
import { BrowserRouter } from 'react-router-dom'
import { OAuthApp } from 'components/app/OAuthApp'
import { Subject } from 'rxjs'
import { Auth0Provider } from '@auth0/auth0-react'
import { Provider } from 'react-redux'
import { store } from 'store/store'
import { createRoot } from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'moment/locale/de'

const httpError$ = new Subject<void>()

axios
  .get('/config.json')
  .catch((error) => {
    console.error(error)
    // TODO: display some generic error page
    return { data: null }
  })
  .then((config) => {
    const container = document.getElementById('root')!
    const root = createRoot(container)
    root.render(
      <React.StrictMode>
        <PorscheDesignSystemProvider>
          <BrowserRouter>
            <Auth0Provider
              domain={config.data.authProviderUrl}
              clientId={config.data.clientId}
              audience={config.data.audience}
              redirectUri={window.location.origin}
              /* There's a bug in Chrome that doesn't allow us to use callback in order to retrieve state and perform navigation.
                                             That's why a workaround utilizing session storage was implemented instead and code/state query params are manually handled in auth-hooks.ts. */
              skipRedirectCallback
            >
              <Provider store={store}>
                <OAuthApp config={config.data} httpError$={httpError$}>
                  <IntlApp config={config.data} httpError$={httpError$.asObservable()} />
                </OAuthApp>
              </Provider>
            </Auth0Provider>
          </BrowserRouter>
        </PorscheDesignSystemProvider>
      </React.StrictMode>
    )

    applyPolyfillsHeader().then(() => definePorscheNavigation(window))
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
