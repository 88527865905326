import { PLinkPure } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import { useLang, useVinFromState } from 'hooks/hooks'
import { useMnoInfoContext } from 'hooks/useMnoInfoContext'
import { useMemo } from 'react'
import 'components/navigation/Navigation.scss'

export const Navigation = ({ backToMyVehiclePrefix }: { backToMyVehiclePrefix?: string }): JSX.Element => {
  const lang = useLang()
  const vin = useVinFromState()
  const productInfo = useMnoInfoContext()

  const backToMyVehicleUrl = useMemo(
    () =>
      productInfo.data?.country && vin && lang
        ? `${backToMyVehiclePrefix}/${productInfo.data.country}/${lang}/vehicles/${vin}`
        : backToMyVehiclePrefix,
    [backToMyVehiclePrefix, lang, productInfo.data?.country, vin]
  )

  return (
    <PLinkPure icon='arrow-head-left' size='small' className='back-to-vehicle'>
      <a href={backToMyVehicleUrl} data-testid='back-to-vehicle'>
        <FormattedMessage id='app.navigation.backToVehicle' />
      </a>
    </PLinkPure>
  )
}
