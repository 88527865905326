import React, { useState } from 'react'
import { Subject } from 'rxjs'
import { PSpinner } from '@porsche-design-system/components-react'
import { useHandleRedirect, useInitApi, useLoginWithRedirect } from 'hooks/auth-hooks'
import 'components/app/App.scss'

export const OAuthApp = ({
  config: { apiUrl },
  httpError$,
  children,
}: {
  config: {
    apiUrl: string
  }
  httpError$: Subject<void>
  children: JSX.Element
}): JSX.Element => {
  const [apiInitialized, setApiInitialized] = useState(false)
  const [authInProgress, setAuthInProgress] = useState(false)

  useHandleRedirect()
  useLoginWithRedirect()
  useInitApi(apiUrl, httpError$, apiInitialized, setApiInitialized, authInProgress, setAuthInProgress)

  return (
    <>
      {apiInitialized ? (
        children
      ) : (
        <div className='spinner-flex'>
          <PSpinner size='inherit' aria={{ 'aria-label': 'Authorizing' }} data-testid='spinner' />
        </div>
      )}
    </>
  )
}
