import React from 'react'
import { PSpinner } from '@porsche-design-system/components-react'
import 'components/CommonStyles.scss'
import { ConnectivityProductUIState } from 'model/uistate/ConnectivityProductUIState'
import { FutureResult } from 'model/FutureResult'
import { usePath } from 'hooks/hooks'

const Rel4LandingPage = ({
  connectivityProductState,
}: {
  connectivityProductState: FutureResult<ConnectivityProductUIState>
}): JSX.Element => {
  usePath(connectivityProductState)
  return (
    <>
      {connectivityProductState.pending && (
        <PSpinner size='large' aria={{ 'aria-label': 'Loading connectivity state' }} data-testid='spinner' />
      )}
    </>
  )
}

export default Rel4LandingPage
